import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row flex-wrap justify-content-start gap-5 mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormField, {
      required: true,
      name: "business_name",
      containerClass: "w-90",
      label: "Business Name",
      placeholder: "Business Name",
      type: "text"
    }),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_email_address",
      containerClass: "w-45",
      label: "Business Email Address",
      placeholder: "Business Email Address",
      type: "email"
    }),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_website",
      containerClass: "w-45",
      label: "Website",
      placeholder: "example.com",
      type: "text"
    }),
    _createVNode(_component_FormField, {
      required: true,
      name: "abn",
      containerClass: "w-45",
      label: "ABN",
      placeholder: "ABN",
      type: "text",
      mask: _ctx.abnMasking
    }, null, 8, ["mask"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "acn",
      containerClass: "w-45",
      label: "ACN",
      placeholder: "ACN",
      type: "text",
      mask: _ctx.acnMasking
    }, null, 8, ["mask"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_phone_number",
      containerClass: "w-45",
      label: "Phone Number",
      placeholder: "Phone Number",
      type: "text",
      mask: _ctx.phoneMasking
    }, null, 8, ["mask"])
  ]))
}