
import { defineComponent } from 'vue';
import FormField from '@/components/forms/FormField.vue';
import mask from '@/utils/mask';

export default defineComponent({
  name: 'business-details-form',
  emits: ['input'],
  props: {
    values: {
      type: Object,
      default: () => {
        undefined;
      },
    },
  },
  components: {
    FormField,
  },
  computed: {
    abnMasking() {
      return mask.business.abn;
    },
    acnMasking() {
      return mask.business.acn;
    },
    phoneMasking() {
      return mask.phone.aus;
    },
    mobileMasking() {
      return mask.mobile.aus;
    },
  },
});
