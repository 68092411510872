
import { defineComponent } from 'vue';
import BusinessDetailsForm from '@/views/new-design/CompleteProfile/BusinessDetailsForm.vue';
import AddressForm from '@/views/new-design/CompleteProfile/AddressForm.vue';

export default defineComponent({
  name: 'business-details',
  props: {
    values: {
      type: Object,
      default: () => undefined,
    },
  },
  emits: [
    'businessCountryChange',
    'businessCountryBlur',
    'businessStateChange',
    'businessStateBlur',
  ],
  components: {
    BusinessDetails: BusinessDetailsForm,
    BusinessAddressForm: AddressForm,
  },
  methods: {
    handleCountryChange(value) {
      this.$emit('businessCountryChange', value);
    },
    handleCountryBlur() {
      this.$emit('businessCountryBlur');
    },
    handleStateChange(value) {
      this.$emit('businessStateChange', value);
    },
    handleStateBlur() {
      this.$emit('businessStateBlur');
    },
  },
});
